import Carousel from "react-bootstrap/Carousel";
import { Container, Row, Col } from "react-bootstrap";
import Farmacia from "../assets/Farmacia 1.jpg";
import Ferreteria from "../assets/Ferreteria 1.jpg";
import Tienda from "../assets/Tienda 1.jpg";
import Libreria from "../assets/Libreria 1.jpg";
import Super from "../assets/Super 1.jpg";
import "../Styles/cards.css";

function Cards2() {
  return (
    <Container className="wrapper-cards">
      <Row className="title-wrapper">
        <Col xs={3}></Col>
        <Col xs={6}>
          <h1 className="cards-title">EPECIAL PARA:</h1>
        </Col>
        <Col xs={3}></Col>
      </Row>
      <Row className="carousel-wrapper">
        <Col xs={1}></Col>
        <Col xs={10}>
          <Carousel className="carousel">
            <Carousel.Item>
              <img
                loading="lazy"
                className="d-block"
                src={Farmacia}
                alt="First slide"
              />
              <Carousel.Caption>
                <h1 className="title-card">Farmacias</h1>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                loading="lazy"
                className="d-block"
                src={Ferreteria}
                alt="Second slide"
              />

              <Carousel.Caption>
                <h1 className="title-card">Ferreterias</h1>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                loading="lazy"
                className="d-block"
                src={Tienda}
                alt="Third slide"
              />

              <Carousel.Caption>
                <h1 className="title-card">Tiendas</h1>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                loading="lazy"
                className="d-block"
                src={Libreria}
                alt="Third slide"
              />

              <Carousel.Caption>
                <h1 className="title-card">Librerias</h1>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                loading="lazy"
                className="d-block"
                src={Super}
                alt="Third slide"
              />

              <Carousel.Caption>
                <h1 className="title-card">Supermercados</h1>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col xs={1}></Col>
      </Row>
    </Container>
  );
}

export default Cards2;

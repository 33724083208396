import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap";
import logo from "../assets/LogoHorizontal_color 1.svg";
import "../Styles/navbar2.css";
import { Link } from "react-router-dom";
import { useState } from "react";

function NavBar2() {
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  return (
    <>
      {["xxl"].map((expand) => (
        <Navbar
          key={expand}
          bg="light"
          expand={expand}
          className="mb-3"
          sticky="top"
        >
          <Container>
            <Navbar.Brand as={Link} to="/">
              <img
                loading="lazy"
                src={logo}
                alt="logo"
                width="100%"
                height="100%"
              />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              onClick={handleShow}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={showOffcanvas}
              onHide={handleClose}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <img loading="lazy" src={logo} alt="logo" width="50%" />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav
                  className="justify-content-end flex-grow-1 pe-3"
                  onClick={handleClose}
                >
                  <Nav.Link as={Link} to="/" className="texto-nav">
                    Inicio
                  </Nav.Link>
                  <Nav.Link as={Link} to="/construccion" className="texto-nav">
                    SynergiaERP
                  </Nav.Link>
                  <Nav.Link as={Link} to="/construccion" className="texto-nav">
                    Servicios
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavBar2;

import { Container, Row } from "react-bootstrap";
import construction from "../assets/construccion.png";
import "../Styles/construccion.css";

export default function Construccion() {
  return (
    <Container className="construccion" id="construccion">
      <Row>
        <img src={construction} alt="construccion" loading="lazy" />
        <h1 className="titulo-construccion">En Construcción </h1>
        <p className="parrafo-construccion">
          Esta página está en construccion, visitela de nuevo pronto.
        </p>
      </Row>
    </Container>
  );
}

import "../Styles/firstpage.css";
import { motion } from "framer-motion";
import { Container, Row, Col } from "react-bootstrap";

export default function FirstPage() {
  return (
    <motion.div
      className="first-page"
      id="inicio"
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
    >
      <Container>
        <Row>
          <Col xs={1}></Col>
          <Col className="col-titulo" xs={6}>
            <motion.h1
              className="titulo"
              initial={{ x: 200 }}
              animate={{ x: 0 }}
              transition={{ delay: 0.5, type: "spring", bounce: 0.5 }}
            >
              Quienes Somos?
            </motion.h1>
            <div className="wrap-parrafo">
              <motion.p
                className="parrafo"
                initial={{ x: 200 }}
                animate={{ x: 0 }}
                transition={{ delay: 0.5, type: "spring", bounce: 0.5 }}
              >
                Somos una empresa comprometida en diseñar el crecimiento de su
                empresa, buscamos ser un aliado de confianza para brindar
                soluciones a la medida con un valor tecnológico agregado
              </motion.p>
            </div>
          </Col>
          <Col xs={5}></Col>
          <Row>
            <Col xs={1}></Col>
            <Col xs={11}>
              <a href="#contacto">
                <motion.button
                  className="CTA"
                  whileHover={{ scale: 1.2 }}
                  whileFocus={{ scale: 1.2 }}
                  whileTap={{ scale: 0.8 }}
                  transition={{
                    type: "spring",
                    bounce: 0.5,
                  }}
                >
                  Contactenos
                </motion.button>
              </a>
            </Col>
          </Row>
        </Row>
      </Container>
    </motion.div>
  );
}

import "../Styles/secondpage.css";
import first from "../assets/nos encanta.png";
import second from "../assets/apoyo.png";
import third from "../assets/aliado.png";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";

export default function SecondPage() {
  return (
    <Container className="secondpage" id="2page">
      <Row>
        <Col xs={2}></Col>
        <Col xs={8}>
          <div className="wrap-title">
            <h1 className="titulo2">Por qué lo hacemos?</h1>
          </div>
        </Col>
        <Col xs={2}></Col>
      </Row>
      <Row className="second">
        <Col xs={1}></Col>{" "}
        <Col className="wrap-text" xs={6}>
          <Row>
            <motion.h1
              className="title-"
              initial={{ y: -200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                type: "spring",
                bounce: 0.5,
                duration: 1,
              }}
            >
              Nos encanta
            </motion.h1>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row>
            <motion.p
              className="parrafo2"
              initial={{ y: -200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                type: "spring",
                bounce: 0.5,
                duration: 1,
              }}
            >
              Somos una empresa comprometida en diseñar el crecimiento de su
              empresa, brindando soluciones a la medida con un valor tecnológico
              agregado.
            </motion.p>
          </Row>
        </Col>
        <Col xs={1}></Col>
        <Col xs={4}>
          <motion.div
            className="wrap-second"
            whileHover={{ scale: 1.1 }}
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1, rotate: [0, 10, 0] }}
            viewport={{ once: true }}
            transition={{
              type: "spring",
              bounce: 0.5,
              duration: 1,
            }}
          >
            <img src={first} alt="" className="img-second" />
          </motion.div>
        </Col>
      </Row>
      <Row className="second">
        <Col xs={4}>
          <motion.div
            className="wrap-text-second"
            whileHover={{ scale: 1.1 }}
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1, rotate: [0, 10, 0] }}
            viewport={{ once: true }}
            transition={{
              type: "spring",
              bounce: 0.5,
              duration: 1,
            }}
          >
            <img src={second} alt="" className="img-second" />
          </motion.div>
        </Col>
        <Col xs={1}></Col>{" "}
        <Col className="wrap-text" xs={6}>
          <Row>
            <motion.h1
              className="title-"
              initial={{ y: -200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                type: "spring",
                bounce: 0.5,
                duration: 1,
              }}
            >
              Apoyo empresarial
            </motion.h1>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row>
            <motion.p
              className="parrafo2"
              initial={{ y: -200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                type: "spring",
                bounce: 0.5,
                duration: 1,
              }}
            >
              Creemos en que el apoyo empresarial es un bien mutuo para nuestros
              clientes y empresa. Si el cliente están bien, el futuro espera.
            </motion.p>
          </Row>
        </Col>
        <Col xs={1}></Col>
      </Row>
      <Row className="second">
        <Col xs={1}></Col>{" "}
        <Col className="wrap-text" xs={6}>
          <Row>
            <motion.h1
              className="title-"
              initial={{ y: -200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                type: "spring",
                bounce: 0.5,
                duration: 1,
              }}
            >
              Aliado Estratégico
            </motion.h1>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row>
            <motion.p
              className="parrafo2"
              initial={{ y: -200, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              viewport={{ once: true }}
              transition={{
                type: "spring",
                bounce: 0.5,
                duration: 1,
              }}
            >
              Ser una empresa de confiar para el cliente, transparentes,
              sinceros, brindar soluciones a la medida con valor tecnológico
              agregado.
            </motion.p>
          </Row>
        </Col>
        <Col xs={1}></Col>
        <Col xs={4}>
          <motion.div
            className="wrap-second"
            whileHover={{ scale: 1.1 }}
            initial={{ x: 200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1, rotate: [0, 10, 0] }}
            viewport={{ once: true }}
            transition={{
              type: "spring",
              bounce: 0.7,
              duration: 1,
            }}
          >
            <img src={third} alt="" className="img-second" />
          </motion.div>
        </Col>
      </Row>
    </Container>
  );
}

import { Container, Row, Col } from "react-bootstrap";
import "../Styles/erp-intro.css";

export default function ERP_Intro() {
  return (
    <div className="erp-intro-back" id="erp-intro1">
      <Container className="erp_intro">
        <Row>
          <Col xs={3}></Col>
          <Col xs={6}>
            {" "}
            <div className="titulo_erp_wrapper">
              <h1 className="titulo_erp">Synergia ERP</h1>
            </div>
          </Col>
          <Col xs={3}></Col>{" "}
          <Row>
            {" "}
            <Col xs={2}></Col>
            <Col xs={8}>
              {" "}
              <p className="parrafo_erp">
                Synergia ERP es un sistema de facturación e inventarios el cual
                controla de manera ágil y segura, cubriendo necesidades en las
                áreas de cotizaciones, ventas, factura electrónica, compras,
                almacén e inventarios de forma centralizada.
              </p>
            </Col>
            <Col xs={2}></Col>
          </Row>
        </Row>
      </Container>
    </div>
  );
}

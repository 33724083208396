import React from "react";
import Cards2 from "./Cards2";
import FirstPage from "./FirstPage";
import SecondPage from "./SecondPage";
import Contacto from "./contacto";
import ERP from "./ERP-intro";

export default function Home() {
  return (
    <div>
      <FirstPage />
      <SecondPage />
      <ERP />
      <Cards2 />
      <Contacto />
    </div>
  );
}
